import React from 'react'
// import Json from '../components/json'
import Html from '../components/html'
import ShopNotices from '../components/shop-notices'
import NewsLetter from '../components/newsletter'
import Layout from '../components/layout'
import CollectionCards from '../components/collection-cards'
import { graphql } from 'gatsby'

export default function PageCollection ({ pageContext, data, location }) {
  const { menuLabel, handle } = pageContext
  const { shop: { productCollections } } = data.wpgraphql
  const collection = productCollections.shopCollection.find(item => item.menuLabel === menuLabel)

  const page = {
    seo: {
      title: `${collection.title} | Primo Toys`,
      metaDesc: collection.description || ''
    },
    slug: handle
  }

  return (
    <Layout
      page={page}
      template='collection'
      path={location.pathname}
    >
      <article>
        <section className='Shop u-headerMargin'>
          <section className='u-sectionPadded u-sectionDivider'>
            <h1
              className='u-centered'
              style={{ marginBottom: '3vw' }}
              dangerouslySetInnerHTML={{ __html: collection.title }}
            />
            <Html className='u-centered'>{collection.description}</Html>
          </section>
          <section className={`Shop-section u-sectionDivider`}>
            <CollectionCards collection={collection} />
          </section>
        </section>
        <section className='u-sectionPadded u-sectionDivider'>
          <ShopNotices />
        </section>
        <NewsLetter />
      </article>
    </Layout>
  )
}

export const pageQuery = graphql`
  query PageCollectionQuery {
    wpgraphql {
      ...ShopPageCollectionsFragment
    }
  }
`
